<template>
  <div>
    <aside
      id="default-sidebar"
      class="sidebar-container fixed top-0 left-0 z-40 h-screen transition-transform -translate-x-full sm:translate-x-0"
      aria-label="Sidebar"
    >
      <div class="h-full px-4 py-4 overflow-y-auto" style="display: flex;flex-direction: column;">
        <div style="flex: 1;">

        
        <div class="px-2 pt-4 pb-6 logo-container">
          <img class="logo" src="../assets/main_logo.png" />
          <p class="publisher">Agent</p>
        </div>
        <ul class="space-y-2 font-medium pt-4">
          <li
            :class="[
              'menu-item hover:bg-gray-100 flex items-center',
              { active: menu.selected },
            ]"
            v-for="(menu, index) in menus"
            :key="index"
            @click="handleMenuItemClick(menu)"
          >
            <img
              class="menu-icon"
              :src="
                require(`../assets/${menu.icon}${
                  menu.selected ? '_selected' : ''
                }.png`)
              "
            />
            <span class="ms-3 menu-text">{{ menu.title }}</span>
          </li>
        </ul>
      </div>
        <div class="menu-item hover:bg-gray-100 flex items-center active" @click="gotoSettingsView">
          <img
              class="menu-icon"
              src="../assets/settings.png"
            />
            <span class="ms-3 menu-text">{{ 'Settings' }}</span>
        </div>
      </div>
    </aside>
  </div>
</template>
<script>
export default {
  name: "PublisherSideBar",
  components: {},
  mixins: [],
  props: {},
  data() {
    return {
      menus: [
        {
          title: "My Articles",
          icon: "article",
          selected: true,
          path: "/publisher/my-articles",
        }
      ],
    };
  },
  computed: {},
  watch: {
    $route() {
      this.menus = this.menus.map((item) => ({
        ...item,
        selected: item.path === this.$route.path,
      }));
    },
  },
  mounted() {
    this.menus = this.menus.map((item) => ({
      ...item,
      selected: item.path === this.$route.path,
    }));
  },
  methods: {
    gotoSettingsView(){
      this.$router.push({
        path:'/publisher/settings'
      })
    },
    handleMenuItemClick(item) {
      this.$router.push(item.path);
    },
  },
};
</script>
<style lang="scss" scoped>
.sidebar-container {
  width: 250px;
  background: #f9f9f9;
  .logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #e2e8f0;

      .publisher {
      background-color: #EDEDED;
      color: #B0B0B0;
      border-radius: 8px;
      padding: 2px 10px;
      box-sizing: border-box;
      margin-left: 10px;
    }
  }
  .logo {
    width: 104px;
    height: 32px;
  }
  .menu-item {
    color: #718096;
    padding: 14px;
    cursor: pointer;
    border-radius: 12px;
    font-size: 14px;
    &.active {
      color: white;
      background: #ec652b;
      font-weight: bold;
    }
    .menu-icon {
      width: 18px;
      height: 18px;
      margin-right: 8px;
    }
  }
}
</style>
