var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('aside',{staticClass:"sidebar-container fixed top-0 left-0 z-40 h-screen transition-transform -translate-x-full sm:translate-x-0",attrs:{"id":"default-sidebar","aria-label":"Sidebar"}},[_c('div',{staticClass:"h-full px-4 py-4 overflow-y-auto",staticStyle:{"display":"flex","flex-direction":"column"}},[_c('div',{staticStyle:{"flex":"1"}},[_vm._m(0),_c('ul',{staticClass:"space-y-2 font-medium pt-4"},_vm._l((_vm.menus),function(menu,index){return _c('li',{key:index,class:[
            'menu-item hover:bg-gray-100 flex items-center',
            { active: menu.selected },
          ],on:{"click":function($event){return _vm.handleMenuItemClick(menu)}}},[_c('img',{staticClass:"menu-icon",attrs:{"src":require(`../assets/${menu.icon}${
                menu.selected ? '_selected' : ''
              }.png`)}}),_c('span',{staticClass:"ms-3 menu-text"},[_vm._v(_vm._s(menu.title))])])}),0)]),_c('div',{staticClass:"menu-item hover:bg-gray-100 flex items-center active",on:{"click":_vm.gotoSettingsView}},[_c('img',{staticClass:"menu-icon",attrs:{"src":require("../assets/settings.png")}}),_c('span',{staticClass:"ms-3 menu-text"},[_vm._v(_vm._s('Settings'))])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"px-2 pt-4 pb-6 logo-container"},[_c('img',{staticClass:"logo",attrs:{"src":require("../assets/main_logo.png")}}),_c('p',{staticClass:"publisher"},[_vm._v("Agent")])])
}]

export { render, staticRenderFns }