<template>
  <div>
    <el-container>
      <el-aside class="home-aside" style="width: 240px">
        <SideBar />
      </el-aside>
      <el-main
        style="
          background-color: #f8f8f8;
          height: 100vh;
          width: 100%;
          padding: 0px;
        "
      >
        <div style="height: 100vh; overflow-y: auto">
          <router-view />
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import SideBar from "../components/PublisherSideBar.vue";
export default {
  name: "Home",
  components: {
    SideBar,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="less">
.home-aside {
  height: 100vh;
  background: white;
  box-shadow: 2px 0px 6px 1px rgba(0, 21, 41, 0.12);
  border-radius: 0px 0px 0px 0px;
}

.menu-item {
  margin-top: 15px;
  cursor: pointer;
  padding-left: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
  color: #ffc569;
  font-size: 16px;
  font-weight: 500;
  background-color: #fff;

  &:hover {
    background-color: #eff1ff;
  }
}

.menu-item-selected {
  color: #ffc569;
  background-color: #eff1ff;
}
</style>
